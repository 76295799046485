export const newsConfig = {
  chunkFileName: 'articles-',
  chunkFileType: '.json',
  chunkSize: 24,
  backHref: '/ueber/neuigkeiten',
  backLabel: 'Alle Neuigkeiten',
  subsidiaryMapping: {
    '2018': {
      allgemein: 'HH',
      keno: 'HE',
      lotto6aus49: 'HH',
      eurojackpot: 'NW',
      gluecksspirale: 'BY',
    },
    '2019': {
      allgemein: 'RP',
      keno: 'HE',
      lotto6aus49: 'RP',
      eurojackpot: 'NW',
      gluecksspirale: 'BY',
    },
    '2020': {
      allgemein: 'RP',
      keno: 'HE',
      lotto6aus49: 'RP',
      eurojackpot: 'NW',
      gluecksspirale: 'BY',
    },
    '2021': {
      allgemein: 'RP',
      keno: 'HE',
      lotto6aus49: 'RP',
      eurojackpot: 'NW',
      gluecksspirale: 'BY',
    },
    '2022': {
      allgemein: 'NI',
      keno: 'HE',
      lotto6aus49: 'NI',
      eurojackpot: 'NW',
      gluecksspirale: 'BY',
    },
    '2023': {
      allgemein: 'NI',
      keno: 'HE',
      lotto6aus49: 'NI',
      eurojackpot: 'NW',
      gluecksspirale: 'BY',
    },
    '2024': {
      allgemein: 'NI',
      keno: 'HE',
      lotto6aus49: 'NI',
      eurojackpot: 'NW',
      gluecksspirale: 'BY',
    },
    '2025': {
      allgemein: 'NI',
      keno: 'HE',
      lotto6aus49: 'NI',
      eurojackpot: 'NW',
      gluecksspirale: 'BY',
    },
    '2026': {
      allgemein: 'NI',
      keno: 'HE',
      lotto6aus49: 'NI',
      eurojackpot: 'NW',
      gluecksspirale: 'BY',
    },
  },
  defaultCategory: 'allgemein',
  allFilter: 'alle',
  filterParam: 'filter',
  filterKey: 'newsFilter',
  articlesKey: 'newsArticles',
  filters: {
    alle: 'Alle anzeigen',
    lotto6aus49: 'LOTTO 6aus49',
    eurojackpot: 'Eurojackpot',
    keno: 'KENO',
    gluecksspirale: 'GlücksSpirale',
    allgemein: 'LOTTO-News',
  },
};
