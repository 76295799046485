import { GameConfig } from '@/config/game';
import { teaserTimeTable } from '@/config/home/teaserTimeTable';
import { getGameById } from './getGameById';

export function getGameLink(gameConfig: GameConfig | undefined): string {
  if (gameConfig) {
    const gameData = getGameById(gameConfig.id);
    return gameData ? gameData.urls.game : '';
  }
  return '';
}

interface GameSwitchListItem {
  id: number;
  path: string;
}

export type GameSwitchList = GameSwitchListItem[];

const paramMapping = {
  no_ejp: 1,
  no_gs: 2,
  no_kn: 3,
  no_ln: 4,
};

export function getGameSwitchList(): GameSwitchList {
  const excludedGames: number[] = [];

  if (typeof window !== 'undefined' && typeof URLSearchParams !== 'undefined') {
    const search = window.location.search;
    const query = new URLSearchParams(search);
    Object.keys(paramMapping).forEach((param) => {
      if (query.get(param) === '1') {
        excludedGames.push(paramMapping[param]);
      }
    });

    return teaserTimeTable.games
      .filter((gameId) => !excludedGames.includes(gameId))
      .filter((gameId) => getGamePage(gameId))
      .map((gameId) => {
        const page = getGamePage(gameId);
        return {
          id: gameId,
          path: page ? page + search : '/',
        };
      });
  }

  function getGamePage(gameId: number | string): string | undefined {
    const gameData = getGameById(gameId);
    return gameData ? gameData.urls.game + '/iframe' : undefined;
  }
}
