import Image from '@/components/Image';
import Link, { LinkType, LinkVariant } from '@/components/Link';
import { LinkGame } from '@/components/LinkGame';
import LinkWrapper from '@/components/LinkWrapper';
import Logo from '@/components/Logo';
import { OddsVariant } from '@/config/odds/OddsVariant';
import winningNumbers from '@/config/odds/winningNumbers';
import { getGameById } from '@/helpers/getGameById';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import HistogramContainer from './HistogrammContainer';
import { ResponsiveTable } from './ResponsiveTable';

const Barometer = dynamic(() => import('./Barometer'));
const ContactForm = dynamic(() => import('./ContactForm'));
const Deadlines = dynamic(() => import('./Deadlines'));
const FAQ = dynamic(() => import('./FAQ'));
const LiveDraw = dynamic(() => import('./LiveDraw'));
const LotteryComparison = dynamic(() => import('./LotteryComparison'));
const LottoHistogram = dynamic(() => import('./LottoHistogram'));
const LottoStatistics = dynamic(() => import('./LottoStatistics'));
const Odds = dynamic(() => import('./Odds'));
const ScriptIntegration = dynamic(() => import('./ScriptIntegration'));
const StateSelector = dynamic(() => import('./StateSelector'));
const TilesGameTeaserItem = dynamic(() => import('./TilesGameTeaserItem'));
const Timeline = dynamic(() => import('./Timeline'));
const TimelineBox = dynamic(() => import('./TimelineBox'));
const Video = dynamic(() => import('./Video'));
const WinningNumbersContainer = dynamic(
  () => import('./WinningNumbersContainer')
);

export default function ContentfulRichtext({ json, links, date = undefined }) {
  return documentToReactComponents(json, {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <i>{text}</i>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <>
          <h1
            className={classNames('Layout__title', {
              'Layout__title--has-date': date,
            })}
          >
            {children}
          </h1>
        </>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="headline">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="headline">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="headline">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h5 className="headline">{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h6 className="headline">{children}</h6>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const topLevelLink =
          node.content.length === 1 && node.content[0].nodeType === 'hyperlink';
        return topLevelLink ? (
          children
        ) : (
          <div className="Richtext">{children}</div>
        );
      },
      [BLOCKS.OL_LIST]: (node, children) => (
        <div className="Richtext">
          <ol>{children}</ol>
        </div>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <div className="Richtext">
          <ul>{children}</ul>
        </div>
      ),
      [BLOCKS.TABLE]: (node, children) => (
        <ResponsiveTable>
          <table>{children}</table>
        </ResponsiveTable>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <th>{children}</th>,
      [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const topLevel = json.content.find((item) => {
          return item.content.length === 3 && node === item.content[1];
        });
        const id = node.data.target.sys.id;
        const entry = links.entries.hyperlink.find(
          (item) => item.sys.id === id
        );

        const uri = entry?.path;
        return topLevel ? (
          <Link
            variant={LinkVariant.Button}
            link={LinkType.Internal}
            label={children}
            linkInternal={{ id: '', path: uri }}
          />
        ) : (
          <LinkWrapper href={uri}>{children}</LinkWrapper>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const topLevel = json.content.find((item) => {
          return (
            // Alleinstehender Link
            (item.content.length === 1 && node === item.content[0]) ||
            // Alleinstehender Link mit leerem Text davor und dahinter
            (item.content.length === 3 &&
              item.content[0].nodeType === 'text' &&
              item.content[0].value === '' &&
              node === item.content[1] &&
              item.content[2].nodeType === 'text' &&
              item.content[2].value === '')
          );
        });

        const uri = String(node.data.uri);
        const internal = uri.startsWith('/');
        const download = uri.includes('.pdf');
        const external = !download && uri.includes('://');
        return topLevel ? (
          <Link
            variant={LinkVariant.Button}
            link={
              internal
                ? LinkType.Internal
                : external
                ? LinkType.External
                : LinkType.Download
            }
            label={children}
            linkInternal={internal && { id: '', path: uri }}
            linkExternal={external && uri}
            linkDownload={download && { id: '', name: '', path: uri }}
          />
        ) : (
          <LinkWrapper
            href={uri}
            target={external || download ? '_blank' : '_self'}
          >
            {children}
          </LinkWrapper>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const id = node.data.target.sys.id;
        const asset = links.assets.block.find((item) => item.sys.id === id);
        return (
          <Image
            src={asset.url}
            alt={asset.title}
            caption={asset.description}
            fullwidth={asset.width > asset.height}
            width={1000}
            boxed
          />
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const id = node.data.target.sys.id;
        const entry = links.entries.block.find((item) => item.sys.id === id);

        switch (entry.__typename) {
          case 'Faq':
            return (
              <FAQ
                nodes={[
                  {
                    uuid: entry.sys.id,
                    nodes: [
                      {
                        id: entry.sys.id,
                        question: entry.title,
                        answer: ContentfulRichtext({
                          json: entry.answer.json,
                          links: entry.answer.links,
                        }),
                      },
                    ],
                  },
                ]}
              />
            );

          case 'Video': {
            return <Video boxed title={entry.title} url={entry.url} />;
          }

          case 'FederalStates': {
            return (
              <StateSelector
                title={entry.title}
                BadenWuerttemberg={entry.badenwuerttemberg}
                Bayern={entry.bayern}
                Berlin={entry.berlin}
                Brandenburg={entry.brandenburg}
                Bremen={entry.bremen}
                Hamburg={entry.hamburg}
                Hessen={entry.hessen}
                MecklenburgVorpommern={entry.mecklenburgvorpommern}
                Niedersachsen={entry.niedersachsen}
                NordrheinWestfalen={entry.nordrheinwestfalen}
                RheinlandPfalz={entry.rheinlandpfalz}
                Sachsen={entry.sachsen}
                SachsenAnhalt={entry.sachsenanhalt}
                SchleswigHolstein={entry.schleswigholstein}
                Saarland={entry.saarland}
                Thueringen={entry.thueringen}
              />
            );
          }

          case 'Lottery':
            switch (entry.type) {
              case 'Annahmeschluss':
                return <Deadlines game={entry.data.game} />;
              case 'Spielen':
                return <LinkGame game={entry.data.game} />;
              case 'Historie':
                return (
                  <Timeline>
                    {entry.data.map((item) => (
                      <TimelineBox key={item.date} {...item} />
                    ))}
                  </Timeline>
                );
              case 'Quoten':
                return (
                  <Odds
                    {...entry.data}
                    variant={VARIANTS[entry.data.game]}
                    winningNumbersData={winningNumbers}
                  />
                );
              case 'Gewinnzahlen':
                return (
                  <WinningNumbersContainer
                    showDate={true}
                    showFooter={true}
                    showTwoDraws={true}
                    date={date}
                    variant={VARIANTS[entry.data.game]}
                  />
                );
              case 'Banner':
                const config = getGameById(entry.data.game);
                return config ? (
                  <div
                    className={`Table__caption Table__caption--${config.shortname} Table__caption--themed`}
                  >
                    <Logo variant={config.logo} />
                  </div>
                ) : (
                  <></>
                );
              case 'Barometer':
                return <Barometer />;
              case 'Histogramm':
                return <HistogramContainer variant={entry.data.variant} />;
              case 'Ziehungshäufigkeit':
                return <LottoStatistics />;
              case 'Live-Ziehung':
                return <LiveDraw />;
              default:
                return <pre>{JSON.stringify(entry, null, 2)}</pre>;
            }

          case 'Container':
            switch (entry.type) {
              case 'Kontakt':
                return <ContactForm />;
              case 'Script':
                return <ScriptIntegration scriptVariant={entry.data.variant} />;
              case 'Vergleichstabelle':
                return <LotteryComparison data={entry.data} />;
              case 'Jackpots':
                return (
                  <div className="TilesGameTeaser">
                    {entry.data.map(({ title, text, game, link }) => {
                      return (
                        <TilesGameTeaserItem
                          key={link}
                          title={title}
                          text={text}
                          game={game}
                          link={{ id: link, path: link }}
                        />
                      );
                    })}
                  </div>
                );
              default:
                return <pre>{JSON.stringify(entry, null, 2)}</pre>;
            }
          default:
            return <pre>{JSON.stringify(entry, null, 2)}</pre>;
        }
      },
    },
    renderText: (text) => {
      return text === '' ? (
        ''
      ) : (
        <span dangerouslySetInnerHTML={replaceAbbreviations(text)} />
      );
    },
  });
}

const VARIANTS = {
  '1': OddsVariant.Eurojackpot,
  '2': OddsVariant.GluecksSpirale,
  '3': OddsVariant.Keno,
  '4': OddsVariant.Lotto6aus49,
  '8': OddsVariant.TotoAW,
  '9': OddsVariant.TotoEW,
};
