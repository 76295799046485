import Button from '@/components/Button';

export interface IButtonConfig {
  value: number | string;
  label?: string;
  title?: string;
  disabled?: boolean;
  onClick?: (value: string | number) => void;
}

export const ButtonGroup = ({
  buttonList,
  onClickButton,
}: {
  buttonList: IButtonConfig[];
  onClickButton?: (value: string | number) => void;
}) => {
  const handleClickButton = (button) =>
    (button.onClick && button.onClick(button.value)) ||
    (onClickButton && onClickButton(button.value)) ||
    null;

  return (
    <div className="ButtonGroup">
      {buttonList.map((button, index) => {
        return (
          <Button
            variant={Button.Variant.Square}
            disabled={button.disabled}
            key={index}
            onClick={() => handleClickButton(button)}
          >
            {button.title && (
              <span className="Button__title">{button.title}</span>
            )}
            {button.label && <span>{button.label}</span>}
          </Button>
        );
      })}
    </div>
  );
};
