import { QuickTipType } from '@/components/GameStepCoupons';
import { Icon, IconVariant } from '@/components/Icon';
import Modal, {
  ModalContent,
  ModalHeader,
  ModalProps,
} from '@/components/Modal';
import { QuickTipsButtonsFields } from '@/components/QuickTipsButtonsFields';
import pushDatalayer, { DataLayerEvent } from '@/utilities/pushDatalayer';
import React from 'react';

export interface ModalQuickTipsCouponAmountProps extends ModalProps {
  id: string;
  amountCoupons: number;
  filledCouponsNumber?: number;
  onClickButton: (amountCouponToFill: number) => void;
  withPlus?: boolean;
  iconVariant?: IconVariant;
  quickTips?: number[];
  type?: QuickTipType;
}

const ModalQuickTipsCouponAmount: React.FC<
  React.PropsWithChildren<ModalQuickTipsCouponAmountProps>
> = ({
  id,
  amountCoupons,
  filledCouponsNumber,
  onClickButton,
  withPlus,
  quickTips,
  iconVariant = IconVariant.QuickTips,
  type,
}) => {
  const handleOnClickButton = (amountCouponToFill) => {
    Modal.close(id);

    pushDatalayer(
      `click_element_name`,
      `Quick-Tipp - ${type} - ${amountCouponToFill}`,
      DataLayerEvent.UserClick
    );

    onClickButton(amountCouponToFill);
  };

  return (
    <Modal id={id}>
      <ModalHeader>
        <Icon variant={iconVariant} />
        <span>Anzahl der Felder</span>
      </ModalHeader>

      <ModalContent>
        <small>
          Die Gewinnwahrscheinlichkeit ändert sich durch die Übernahme der hier
          vorgeschlagenen Quick-Tipps nicht.
        </small>
        <h2 className="h-text-center">
          Wie viele Felder sollen befüllt werden?
        </h2>

        <QuickTipsButtonsFields
          onClickButton={handleOnClickButton}
          amountCoupons={amountCoupons}
          filledCouponsNumber={filledCouponsNumber}
          withPlus={withPlus}
          quickTips={quickTips}
        />
      </ModalContent>
    </Modal>
  );
};

export default ModalQuickTipsCouponAmount;
