import { CmsAsset } from '@/components/CmsInterfaces';
import { Icon, IconSize, IconVariant } from '@/components/Icon';
import classnames from 'classnames';
import React from 'react';

interface Service {
  readonly url?: string;
  readonly mobileUrl?: string;
  readonly Icon: IconVariant;
  readonly name?: string;
  readonly target?: string;
  readonly params?: string;
  readonly imageParam?: string;
  readonly hideOnNoImage?: boolean;
}

interface Services {
  [service: string]: Service;
}

const SERVICES: Services = {
  Facebook: {
    url: 'https://www.facebook.com/sharer/sharer.php?',
    Icon: IconVariant.Facebook,
    params: 'u=$link',
  },
  Twitter: {
    url: 'https://twitter.com/intent/tweet?',
    Icon: IconVariant.Twitter,
    name: 'X/Twitter',
    params: 'text=$title&url=$link',
  },
  Whatsapp: {
    url: 'https://web.whatsapp.com/send?',
    mobileUrl: 'whatsapp://send?',
    Icon: IconVariant.Whatsapp,
    params: 'text=$title - $link',
  },
};

interface SocialShareProps {
  readonly pagePath?: string;
  readonly title?: string;
  readonly image?: CmsAsset | string | null;
}

function getImageUrl(image: CmsAsset | string | null): string {
  if (image && typeof image !== 'string' && image.path) {
    return `https://www.lotto.de${image.path}`;
  }
  if (typeof image === 'string') {
    return `https://www.lotto.de${image}`;
  }

  return 'https://www.lotto.de/assets/images/logo-main.svg';
}

function getShareUrl(
  service: Service,
  pagePath: string,
  title: string | null,
  mobile: boolean = false,
  image: string | null = null
): string {
  let params =
    service.params && title && pagePath
      ? service.params.replace('$link', `https://www.lotto.de${pagePath}`)
      : null;

  if (params && title) {
    params = params.replace('$title', title);
  }

  const imageParam =
    service.imageParam && image
      ? service.imageParam.replace('$image', image)
      : null;

  return encodeURI(
    `${mobile ? service.mobileUrl : service.url}${params ? params : ''}${
      imageParam ? imageParam : ''
    }`
  );
}

export const SocialShare: React.FC<
  React.PropsWithChildren<SocialShareProps>
> = ({ pagePath = null, title = null, image = null }) => {
  return pagePath ? (
    <div className="SocialShare">
      <h2 className="headline">Diese Seite teilen über</h2>
      <div className="SocialShare__container">
        {Object.keys(SERVICES).map((key, idx) => {
          const service = SERVICES[key];
          if (image === null && service.hideOnNoImage) {
            return null;
          }

          return (
            <div key={idx}>
              {service.url && (
                <a
                  href={getShareUrl(
                    service,
                    pagePath,
                    title,
                    false,
                    getImageUrl(image)
                  )}
                  target={service.target || '_blank'}
                  className={classnames('SocialShare__link', {
                    SocialShare__desktop: !!service.mobileUrl,
                  })}
                  aria-label={`Externer Link: Diese Seite teilen über ${
                    service.name || key
                  } (öffnet neues Fenster)`}
                >
                  <div className="SocialShare__button">
                    <Icon variant={service.Icon} size={IconSize.Large} />
                  </div>
                </a>
              )}
              {service.mobileUrl && (
                <a
                  href={getShareUrl(
                    service,
                    pagePath,
                    title,
                    true,
                    getImageUrl(image)
                  )}
                  target={service.target || '_blank'}
                  rel="noopener"
                  className="SocialShare__link SocialShare__mobile"
                  aria-label={`Externer Link: Diese Seite teilen über ${
                    service.name || key
                  } (öffnet neues Fenster)`}
                >
                  <div className="SocialShare__button">
                    <Icon variant={service.Icon} size={IconSize.Large} />
                  </div>
                </a>
              )}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};
