import Button from '@/components/Button';
import type { DrawTypeConfig } from '@/config/game';

export const QuickTipsButtonsKenoType = ({
  onClickButton,
  drawTypes,
}: {
  drawTypes: DrawTypeConfig[];
  onClickButton: (kenoType: number) => void;
}) => {
  return (
    <div className="QuickTipsButtonsKenoType">
      {drawTypes.map((drawType) => (
        <div className="QuickTipsButtonsKenoType__item" key={drawType.value}>
          <Button
            variant={Button.Variant.Square}
            onClick={() => onClickButton(drawType.value)}
          >
            <span>KENO-Typ</span>
            <span className="QuickTipsButtonsKenoType__heading">
              {drawType.value}
            </span>
          </Button>
        </div>
      ))}
    </div>
  );
};
