import { LottoBall } from '@/components/LottoBall';
import classnames from 'classnames';
import React from 'react';

interface StatisticsBarProps {
  label: string;
  value: number;
  maxValue: number;
  highlight?: boolean;
  lottoBall?: boolean;
  isLoading?: boolean;
}

const StatisticsBar: React.FC<React.PropsWithChildren<StatisticsBarProps>> = ({
  label,
  value,
  maxValue = value,
  highlight = false,
  lottoBall = false,
  isLoading = false,
}) => {
  return (
    <div className="StatisticsBar">
      {lottoBall ? (
        <div className="StatisticsBar__label StatisticsBar__label--lottoBall">
          <LottoBall value={parseInt(label)} />
        </div>
      ) : (
        <div className="StatisticsBar__label">{label}</div>
      )}
      <div className="StatisticsBar__bar">
        <div
          className={classnames('StatisticsBar__fill', {
            'StatisticsBar__fill--highlight': highlight,
            'StatisticsBar__fill--loading': isLoading,
          })}
          style={{ width: (value / maxValue) * 100 + '%' }}
        >
          <div className="StatisticsBar__count">{value}×</div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsBar;
