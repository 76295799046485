import { Button } from '@/components/Button';
import React from 'react';

export interface LottoHistogramNumberInputProps {
  handleSubmit: any;
  number: number | null;
  disabled: boolean;
}

interface LottoHistogramNumberInputState {
  inputValue: number | null;
}

export default class LottoHistogramNumberInput extends React.Component<
  LottoHistogramNumberInputProps,
  LottoHistogramNumberInputState
> {
  constructor(props) {
    super(props);
    this.state = { inputValue: null };
  }

  handleOnChangeInput = ($event) =>
    this.setState({ inputValue: parseInt($event.target.value) });

  render() {
    return (
      <div className="HistogramNumberInput">
        <form
          onSubmit={($event) =>
            this.props.handleSubmit($event, this.state.inputValue)
          }
        >
          <input
            id="histogramNumberInput"
            min="1"
            max="49"
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            name="histogramYear"
            onChange={this.handleOnChangeInput}
          />
          <Button
            variant={Button.Variant.Loading}
            isLoading={this.props.disabled}
            type="submit"
            disabled={this.props.disabled}
          >
            {this.props.disabled ? 'Wird überprüft' : 'Zahl überprüfen'}
          </Button>
        </form>
      </div>
    );
  }
}
