import React from 'react';

/**
 * Traverses a tree of React elements and calls the provided callback function on each node.
 *
 * @param {React.ReactElement} node - The root of the tree to start traversal from.
 * @param {Function} callback - The function to be called on each node of the tree.
 * @return {void} This function does not return anything.
 */
export function walkTree(
  node: React.ReactElement,
  callback: (node: React.ReactElement) => void
): void {
  callback(node);

  if (node.props && node.props.children) {
    React.Children.forEach(node.props.children, (child) => {
      if (React.isValidElement(child)) {
        walkTree(child, callback);
      }
    });
  }
}
