import LottoHistogramDrawYears from '@/components/LottoHistogramDrawYears';
import LottoHistogramNumberInput from '@/components/LottoHistogramNumberInput';
import OfflineInfo from '@/components/OfflineInfo';
import API from '@/config/api';
import apiRequest from '@/helpers/apiRequest';
import isOffline from '@/utilities/isOffline';
import React from 'react';

interface LottoHistogramState {
  histogramData?: any;
  histogramNumber: number | null;
  currentlyLoading: boolean;
}
export default class LottoHistogram extends React.Component<
  {},
  LottoHistogramState
> {
  constructor(props) {
    super(props);
    this.state = {
      histogramData: null,
      histogramNumber: null,
      currentlyLoading: false,
    };
  }

  async fetchHistogram() {
    const histogramNumber = this.state.histogramNumber;
    const apiUrl = `${API.stats.lottoHistogram}/${histogramNumber}`;
    let response;
    try {
      response = histogramNumber ? await apiRequest(apiUrl) : null;
    } catch (error) {
      response = null;
      console.error('LottoHistogram - fetchHistogram', error);
    }

    this.setState(() => ({ histogramData: response, currentlyLoading: false }));
  }

  handleOnSubmitInput = ($event, newNumber) => {
    $event.preventDefault();
    if (isOffline()) {
      OfflineInfo.open();
    } else if (newNumber) {
      this.setState(
        {
          histogramNumber: newNumber,
          currentlyLoading: true,
        },
        this.fetchHistogram
      );
    }
  };

  render() {
    return (
      <>
        <div className="LottoHistogram box box--large-padding">
          <label
            htmlFor="histogramNumberInput"
            className="LottoHistogram__label"
          >
            Ihre Glückszahl
          </label>
          <LottoHistogramNumberInput
            handleSubmit={this.handleOnSubmitInput}
            number={this.state.histogramNumber}
            disabled={this.state.currentlyLoading}
          />
        </div>
        {this.state.histogramData && (
          <LottoHistogramDrawYears data={this.state.histogramData} />
        )}
      </>
    );
  }
}
