import PageContentful from '@/components/PageContentful';
import requestPage from '@/contentful/requestPage';
import requestPageCollection from '@/contentful/requestPageCollection';

export default PageContentful;

export async function getStaticProps({ preview, params }) {
  const slug = '/' + params.slug.join('/');
  const { sys } = (await requestPageCollection({ preview }))['pageCollection'][
    'items'
  ].find((item) => item.path === slug);
  const data = await requestPage({ id: sys.id, preview });
  const theme = slug.includes('/lotto-6aus49/')
    ? 'lotto6aus49'
    : slug.includes('/eurojackpot/')
    ? 'eurojackpot'
    : slug.includes('/gluecksspirale/')
    ? 'gluecksspirale'
    : slug.includes('/keno/')
    ? 'keno'
    : slug.includes('/rubbellose/')
    ? 'rubbellose'
    : slug.includes('/ueber/toto')
    ? 'toto'
    : '';
  return { props: { ...data['page'], theme } };
}

export const getStaticPaths = async ({ preview }) => {
  return {
    paths: (await requestPageCollection({ preview }))['pageCollection'][
      'items'
    ].map((item) => {
      return {
        params: {
          slug: item.path.slice(1).split('/'),
        },
      };
    }),
    fallback: false,
  };
};
