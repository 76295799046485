import Button from '@/components/Button';
import { ButtonGroup } from '@/components/ButtonGroup';
import { createPlural } from '@/helpers/createPlural';

export const QuickTipsButtonsFields = ({
  amountCoupons,
  filledCouponsNumber,
  onClickButton,
  name,
  quickTips = [1, 2, 4, 6, 8],
  withPlus = false,
}: {
  amountCoupons: number;
  filledCouponsNumber?: number;
  onClickButton: (amountCouponToFill: number, name?: string) => void;
  name?: string;
  withPlus?: boolean;
  quickTips?: number[];
}) => {
  const fieldNumbers = quickTips.filter((_number) => amountCoupons >= _number);

  const _filledCouponsNumber = filledCouponsNumber || 0;

  createPlural();

  const buttonList = fieldNumbers.map((amountCouponToFill) => {
    return {
      disabled: amountCoupons - _filledCouponsNumber < amountCouponToFill,
      label: ' ' + createPlural(amountCouponToFill, ['Feld', 'Felder']),
      onClick: () => onClickButton(amountCouponToFill, name),
      title: (withPlus ? '+' : '') + amountCouponToFill,
      value: amountCouponToFill,
    };
  });

  return (
    <div className="QuickTipsButtonsFields">
      <ButtonGroup buttonList={buttonList} />
      {amountCoupons - _filledCouponsNumber !== 0 && (
        <p>
          <Button
            variant={Button.Variant.Primary}
            onClick={() => {
              onClickButton(amountCoupons, name);
            }}
          >
            Komplett befüllen
          </Button>
        </p>
      )}
    </div>
  );
};
