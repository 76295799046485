import { ArticleImprint } from '@/components/ArticleImprint';
import { CmsPage } from '@/components/CmsInterfaces';
import ContentfulRichtext from '@/components/ContentfulRichtext';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { Layout } from '@/components/Layout';
import OfflineInfo from '@/components/OfflineInfo';
import Page from '@/components/Page';
import { SocialShare } from '@/components/SocialShare';
import { newsConfig } from '@/config/news';
import classnames from 'classnames';

interface PageContentculProps extends CmsPage {
  title: string;
  theme: string;
}

export default class PageContentful extends Page<PageContentculProps> {
  render() {
    const { title, theme, path, draw, publication } = this.props;
    const date = publication || draw;
    return (
      <main
        className={classnames('page', {
          [`page--${theme}`]: !!theme,
          'page-article': publication || draw,
        })}
      >
        <Header parentPage={theme} />
        <Layout
          theme={theme}
          path={path}
          date={date}
          backHref={date ? newsConfig.backHref : undefined}
          backLabel={date ? newsConfig.backLabel : undefined}
        >
          {ContentfulRichtext({
            json: this.props.richtext.json,
            links: this.props.richtext.links,
            date: draw,
          })}
          <SocialShare pagePath={path} title={title} />
          {date && <ArticleImprint category={theme} date={date} />}
        </Layout>
        <Footer />
        <OfflineInfo />
      </main>
    );
  }
}
