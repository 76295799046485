import React from 'react';

export const Richtext = ({
  children,
  html,
}: {
  children: string | React.ReactNode;
  html?: boolean;
}) => {
  return html && typeof children === 'string' ? (
    <div className="Richtext" dangerouslySetInnerHTML={{ __html: children }} />
  ) : (
    <div className="Richtext">{children}</div>
  );
};
