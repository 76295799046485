import { useEffect, useState } from 'react';

/**
 * Renders a component only on client to avoid rehydration issues.
 * https://joshwcomeau.com/react/the-perils-of-rehydration/
 */
export default function ClientOnly({ children }) {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return isMounted ? children : null;
}
