import { GamePriceType } from '@/helpers/calculateGamePrice';
import numeral from 'numeral';

if ((numeral as any).locales['de-custom'] === undefined) {
  numeral.register('locale', 'de-custom', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: () => {
      return '.';
    },
    currency: {
      symbol: '€',
    },
  });
  numeral.locale('de-custom');
}

export default function defaultnumberToCurrency(
  price: GamePriceType,
  currency: string = '€',
  nbsp: boolean = false,
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
): string {
  const fractions = Math.max(minimumFractionDigits, maximumFractionDigits);
  const fractionFormat = fractions > 0 ? '.' + '0'.repeat(fractions) : '';

  currency = currency === '€' || currency === 'EUR' ? '€' : 'DM';

  return price === null
    ? '--,--'
    : numeral(price).format(`0,0${fractionFormat}`) +
        (nbsp ? '&nbsp;' : ' ') +
        currency;
}
