import classnames from 'classnames';

import { Icon, IconVariant } from '@/components/Icon';
import LinkWrapper from '@/components/LinkWrapper';
import Logo from '@/components/Logo';
import { ID_TOTO_AW, ID_TOTO_EW } from '@/config/game/ids';
import { getGameById } from '@/helpers/getGameById';
import { getGameLink } from '@/helpers/getGameLink';

export const LinkGame = ({
  game,
  small,
}: {
  game: string;
  small?: boolean;
}) => {
  const config = getGameById(game);
  const href = getGameLink(config);
  const label =
    config.id === ID_TOTO_AW || config.id === ID_TOTO_EW ? 'online' : 'spielen';
  return config ? (
    <p className="h-spacer-larger-top">
      <LinkWrapper
        href={href}
        className={classnames('LinkGame', `LinkGame--${config.shortname}`, {
          'LinkGame--small': small || false,
        })}
      >
        <span className="LinkGame__logo">
          <Logo variant={config.logo} title={config.title} />
        </span>
        <span className="LinkGame__label">
          {config.title} {label}
        </span>
        <span className="LinkGame__icon">
          <Icon variant={IconVariant.ArrowRight} />
        </span>
      </LinkWrapper>
    </p>
  ) : null;
};
