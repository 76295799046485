import React from 'react';
import API from '../config/api';
import { OddsVariant } from '../config/odds/OddsVariant';
import apiRequest from '../helpers/apiRequest';
import isOffline from '../utilities/isOffline';
import { Button } from './Button';
import OfflineInfo from './OfflineInfo';
import { WinningNumbers } from './WinningNumbers';

interface IWinningGame {
  drawDate: number;
  drawNumbersCollection: Array<{ index: number; drawNumber: number }>;
  extraNumber: number | null;
  superNumber: number | null;
  superNumber2: number | null;
}

interface LottoLuckyNumbersState {
  luckyNumbers: string[];
  winningGames?: IWinningGame[];
  currentlyLoading: boolean;
  showingNoResultAlert: boolean;
  showingNumLuckyNumbersAlert: boolean;
  showingSameLuckyNumbersAlert: boolean;
}
export default class LottoLuckyNumbers extends React.Component<
  {},
  LottoLuckyNumbersState
> {
  constructor(props) {
    super(props);
    this.state = {
      luckyNumbers: ['', '', '', '', '', ''],
      currentlyLoading: false,
      showingNoResultAlert: false,
      showingNumLuckyNumbersAlert: true,
      showingSameLuckyNumbersAlert: false,
    };
  }
  async fetchWinningGames() {
    const luckyNumbers = this.state.luckyNumbers
      .join(',')
      .replace(/(^,*)|(,*$)/g, '')
      .replace(/,,+/, ',');
    const apiUrl = `${API.stats.lottoLuckyNumbers}/${luckyNumbers}`;

    let response;
    try {
      this.setState({ showingNoResultAlert: false });
      response = await apiRequest(apiUrl);
      if (response.length === 0) {
        this.setState({ showingNoResultAlert: true });
      }
    } catch (error) {
      console.error('LottoLuckyNumbers - fetchWinningGames failed', error);
    }

    this.setState(() => ({ winningGames: response, currentlyLoading: false }));
  }

  handleOnSubmitForm = ($event) => {
    $event.preventDefault();
    if (isOffline()) {
      return OfflineInfo.open();
    }

    if (!this.state.currentlyLoading) {
      this.setState(
        { currentlyLoading: true, showingNumLuckyNumbersAlert: false },
        this.fetchWinningGames
      );
      return;
    }

    return;
  };

  handleOnChangeInput = ($event, idx) => {
    const value = $event.target.value;
    this.setState(
      (oldState) => {
        const newNumbers = [...oldState.luckyNumbers];
        newNumbers[idx] = value ? value : '';
        return {
          luckyNumbers: newNumbers,
        };
      },
      () => {
        this.checkMinNumLuckyNumbers();
        this.checkSameLuckyNumbers();
      }
    );
  };

  checkMinNumLuckyNumbers = () => {
    this.setState({
      showingNumLuckyNumbersAlert:
        this.state.luckyNumbers.filter(String).length < 2,
    });
    return;
  };

  checkSameLuckyNumbers = () => {
    this.setState({
      showingSameLuckyNumbersAlert:
        this.state.luckyNumbers.filter(String).length !==
        new Set(this.state.luckyNumbers.filter(String)).size,
    });
  };

  drawWinningGames(): JSX.Element {
    const games = this.state.winningGames;
    if (games && games.length) {
      const sortedGames = games
        .sort((gameA, gameB) => gameA.drawDate - gameB.drawDate)
        .reverse();

      return (
        <div className="LuckyNumbers__games">
          {sortedGames.map((game, idx) => (
            <WinningNumbers
              key={idx}
              winningNumbers={{
                drawDate: game.drawDate,
                drawNumbersCollection: game.drawNumbersCollection,
                extraNumber: game.extraNumber,
                superNumber: game.superNumber,
                superNumber2: game.superNumber2,
              }}
              variant={OddsVariant.Lotto6aus49}
              hideAdditional={true}
              showDate={true}
            />
          ))}
        </div>
      );
    }
    return (
      <div className="LuckyNumbers box">
        <span className="LuckyNumbers__label">Kein Treffer</span>
        <p className="LuckyNumbers__text">
          Die von Ihnen eingegebenen Zahlen sind in dieser Kombination bisher
          noch nicht gezogen worden.
        </p>
      </div>
    );
  }

  render(): JSX.Element {
    return (
      <>
        <div className="LuckyNumbers box">
          <span id="lucky-numbers-6" className="LuckyNumbers__label">
            Ihre 6 Glückszahlen
          </span>
          <form onSubmit={($event) => this.handleOnSubmitForm($event)}>
            <div className="LuckyNumbers__InputGroup">
              {[0, 1, 2, 3, 4, 5].map((value) => (
                <input
                  aria-labelledby="lucky-numbers-6"
                  key={value}
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  min={1}
                  max={49}
                  value={this.state.luckyNumbers[value]}
                  onChange={($event) => this.handleOnChangeInput($event, value)}
                />
              ))}
            </div>
            <Button
              variant={Button.Variant.Loading}
              isLoading={this.state.currentlyLoading}
              type="submit"
              disabled={
                this.state.currentlyLoading ||
                this.state.showingNumLuckyNumbersAlert ||
                this.state.showingSameLuckyNumbersAlert
              }
            >
              {this.state.currentlyLoading
                ? 'Werden überprüft'
                : 'Zahlen überprüfen'}
            </Button>
          </form>
          {this.state.showingNoResultAlert && (
            <div className="LuckyNumbers__Alert">
              Diese Zahlenkombination wurde noch nie gezogen.
            </div>
          )}
          {this.state.showingNumLuckyNumbersAlert && (
            <div className="LuckyNumbers__Alert">
              Bitte geben Sie mindestens 2 Zahlen ein.
            </div>
          )}
          {this.state.showingSameLuckyNumbersAlert && (
            <div className="LuckyNumbers__Alert">
              Bitte geben Sie nicht die gleiche Zahl mehrfach ein.
            </div>
          )}
        </div>
        {this.state.winningGames &&
          this.state.winningGames.length > 0 &&
          this.drawWinningGames()}
      </>
    );
  }
}
