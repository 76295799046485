const winningNumbers = {
  '1': {
    gameType: { id: 16, name: 'Eurojackpot Dienstag' },
    currency: { id: 1, name: 'EUR' },
  },
  '2': {
    gameType: { id: 12, name: 'GlücksSpirale' },
    currency: { id: 1, name: 'EUR' },
  },
  '3': [
    {
      gameType: { id: 14, name: 'KENO' },
      currency: { id: 1, name: 'EUR' },
    },
  ],
  '4': [
    {
      gameType: { id: 2, name: 'LOTTO 6aus49 Samstag' },
      currency: { id: 1, name: 'EUR' },
      game77: {
        gameType: { id: 4, name: 'SPIEL77 Samstag' },
        currency: { id: 1, name: 'EUR' },
      },
      super6: {
        gameType: { id: 6, name: 'SUPER6 Samstag' },
        currency: { id: 1, name: 'EUR' },
      },
    },
    {
      gameType: { id: 1, name: 'LOTTO 6aus49 Mittwoch' },
      currency: { id: 1, name: 'EUR' },
      game77: {
        gameType: { id: 3, name: 'SPIEL77 Mittwoch' },
        currency: { id: 1, name: 'EUR' },
      },
      super6: {
        gameType: { id: 5, name: 'SUPER6 Mittwoch' },
        currency: { id: 1, name: 'EUR' },
      },
    },
  ],
  '5': [
    {
      gameType: null,
      currency: { id: 1, name: 'EUR' },
    },
  ],
  '6': { currency: { id: 1, name: 'EUR' } },
  '7': { currency: { id: 1, name: 'EUR' } },
};

export default winningNumbers as WinningNumbersData;

export interface LastWinningNumbersItem {
  [props: string]: any;
}

export type LastWinningNumbers =
  | LastWinningNumbersItem
  | LastWinningNumbersItem[];

export interface WinningNumbersData {
  [game: string]: LastWinningNumbers | null;
}
