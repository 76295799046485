import React from 'react';
import LottoHistogram from './LottoHistogram';
import LottoLuckyNumbers from './LottoLuckyNumbers';

const HistogramVariants = {
  histogram: LottoHistogram,
  luckyNumbers: LottoLuckyNumbers,
};

export interface HistogramContainerProps {
  variant: string;
}

const HistogramContainer: React.FC<
  React.PropsWithChildren<HistogramContainerProps>
> = ({ variant }) => {
  const Component = HistogramVariants[variant] || LottoHistogram;
  return <Component />;
};

export default HistogramContainer;
