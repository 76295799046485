import { Contact } from '@/components/Contact';
import { newsConfig } from '@/config/news';

export function ArticleImprint({ category, date }) {
  return (
    <Contact contactSubsidiary={subsidiaryState()} usePressAddress showIntro />
  );

  function subsidiaryState() {
    const year = new Date(date).getFullYear().toString();
    const { subsidiaryMapping } = newsConfig;
    let _year: string = year;
    if (!(year in subsidiaryMapping)) {
      const sortedYears = Object.keys(subsidiaryMapping).sort(
        (a: string, b: string) => +a - +b
      );
      const earliest: string = sortedYears[0];
      const latestYear: string = sortedYears[sortedYears.length - 1];
      _year = +year < +earliest ? earliest : latestYear;
    }

    const subsidiaryMappingByYear = subsidiaryMapping[_year];

    return (
      subsidiaryMappingByYear[category] || subsidiaryMappingByYear.allgemein
    );
  }
}
