import { extractText } from '@/helpers/extractText';
import { walkTree } from '@/helpers/walkTree';
import React from 'react';

export function ResponsiveTable({ children }) {
  const ths = [];
  const trs = [];
  const cols = [];

  walkTree(children, (node) => {
    if (node.type === 'th') {
      ths.push(node);
      cols.push(extractText(node));
    }
    if (node.type === 'tr') {
      trs.push(node);
    }
  });

  return (
    <div className="Table Table--compact">
      <div className="Table__wrap">
        <div className="Table__container">
          <table>
            <thead>
              <tr>{ths}</tr>
            </thead>
            <tbody>
              {trs.slice(1).map((row, index) => (
                <tr key={index}>
                  {React.Children.map(row.props.children, (child, index) => (
                    <td data-head={cols[index]}>{child.props.children}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
