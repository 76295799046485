import StatisticsBar from '@/components/StatisticsBar';
import React from 'react';

interface ICount {
  count: number;
  year: number;
}

export interface LottoHistogramProps {
  data: { counts: ICount[] };
}

const LottoHistogramDrawYears: React.FC<
  React.PropsWithChildren<LottoHistogramProps>
> = ({ data }) => {
  const maxCount = Math.max(...data.counts.map((count) => count.count));

  return (
    <div className="DrawYears">
      {data.counts.map((year, idx) => (
        <StatisticsBar
          key={idx}
          label={year.year.toString()}
          value={year.count}
          maxValue={maxCount}
        />
      ))}
    </div>
  );
};

export default LottoHistogramDrawYears;
