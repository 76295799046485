import { Icon, IconVariant } from '@/components/Icon';
import Modal, {
  ModalContent,
  ModalHeader,
  ModalProps,
} from '@/components/Modal';
import { QuickTipsButtonsKenoType } from '@/components/QuickTipsButtonsKenoType';
import { DrawTypeConfig } from '@/config/game';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';
import React from 'react';

interface ModalQuickTipDrawTypesProps extends ModalProps {
  drawTypes: DrawTypeConfig[];
  headerIconVariant?: IconVariant;
  onClickButton: (amount: number) => void;
}

const ModalQuickTipDrawTypes: React.FC<
  React.PropsWithChildren<ModalQuickTipDrawTypesProps>
> = ({
  id,
  drawTypes,
  headerIconVariant = IconVariant.Random,
  onClickButton,
}) => {
  const handleOnClickButton = (amount: number) => {
    Modal.close(id);
    onClickButton(amount);
  };

  const text = replaceAbbreviations(
    'Der KENO-Typ entspricht der Anzahl der getippten Zahlen je Spielfeld. KENO-Typ 2 sind 2 Zahlen, KENO-Typ 10 sind 10 Zahlen usw.  Jeder KENO-Typ folgt einem eigenen Gewinnplan mit festen Quoten. Damit bestimmt der jeweilige KENO-Typ Ihren möglichen Gewinn. Je höher der KENO-Typ desto höher der mögliche maximale Gewinn.'
  );
  return (
    <Modal id={id}>
      <ModalHeader>
        <Icon variant={headerIconVariant} />
        <span>KENO-Typ</span>
      </ModalHeader>

      <ModalContent>
        <h4>Welchen KENO-Typen möchten Sie spielen?</h4>
        <p dangerouslySetInnerHTML={text} />

        <QuickTipsButtonsKenoType
          drawTypes={drawTypes}
          onClickButton={handleOnClickButton}
        />
      </ModalContent>
    </Modal>
  );
};

export default ModalQuickTipDrawTypes;
