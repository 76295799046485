import { Icon, IconSize, IconVariant } from '@/components/Icon';
import { NotificationBanner } from '@/components/NotificationBanner';
import { Portal } from '@/components/Portal';
import React from 'react';

interface IOfflineInfoState {
  isOpen: boolean;
  isOffline: boolean;
}

const CHANGE_OFFLINE_INFO = 'CHANGE_OFFLINE_INFO';

class OfflineInfo extends React.Component<{}, IOfflineInfoState> {
  static open() {
    dispatchEvent(
      new CustomEvent(CHANGE_OFFLINE_INFO, { detail: { value: true } })
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isOffline: false,
    };
  }

  updateOfflineState = () => {
    this.setState(() => ({ isOffline: !navigator.onLine }));
  };

  handleOnClose = () => {
    this.setStateIsOpen(false);
  };

  handleEventOfflineInfo = ($event: CustomEvent) =>
    this.setStateIsOpen($event.detail.value);

  setStateIsOpen = (value: boolean) => this.setState(() => ({ isOpen: value }));

  componentDidMount() {
    this.updateOfflineState();
    this.addEvents();
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  render() {
    const { isOpen, isOffline } = this.state;
    return (
      <Portal selector="#NotificationBannerPortal">
        <NotificationBanner
          isOpen={isOpen && !!isOffline}
          title="Sie sind offline"
          text="Diese Funktion benötigt eine aktive Verbindung zum Internet."
          icon={
            <Icon variant={IconVariant.Offline} size={IconSize.ExtraLarge} />
          }
          onClose={this.handleOnClose}
        />
      </Portal>
    );
  }

  private addEvents() {
    window.addEventListener(CHANGE_OFFLINE_INFO, this.handleEventOfflineInfo);

    ['online', 'offline'].forEach((name) =>
      window.addEventListener(name, this.updateOfflineState)
    );
  }

  private removeEvents() {
    window.removeEventListener(
      CHANGE_OFFLINE_INFO,
      this.handleEventOfflineInfo
    );

    ['online', 'offline'].forEach((name) =>
      window.removeEventListener(name, this.updateOfflineState)
    );
  }
}

export default OfflineInfo;
