import { CmsAsset, CmsLink } from '@/components/CmsInterfaces';
import { Icon, IconVariant } from '@/components/Icon';
import LinkWrapper from '@/components/LinkWrapper';
import classnames from 'classnames';
import React, { ReactNode } from 'react';

export enum LinkType {
  Internal = 'Internal',
  External = 'External',
  Download = 'Download',
}

export enum LinkVariant {
  Default = 'Link--default',
  Button = 'Link--button',
}

export interface LinkProps {
  label: string | ReactNode;
  link: LinkType;
  linkExternal?: string;
  linkInternal?: CmsLink;
  linkDownload?: CmsAsset;
  noFollow?: boolean;
  variant?: LinkVariant;
  ankerId?: string;
}

export const getHref = (props: {
  link: LinkType;
  linkExternal?: string;
  linkInternal?: CmsLink;
  linkDownload?: CmsAsset;
  ankerId?: string;
}): string | undefined => {
  const { linkDownload, linkExternal, linkInternal } = props;
  if (linkDownload) {
    return linkDownload.path;
  }
  if (
    props.link === LinkType.External &&
    typeof props.linkExternal !== 'undefined'
  ) {
    return linkExternal;
  } else {
    return linkInternal?.path;
  }
};

class Link extends React.Component<LinkProps> {
  private get isExternal(): boolean {
    return (
      this.props.link === LinkType.External &&
      typeof this.props.linkExternal !== 'undefined'
    );
  }

  private get isDownload(): boolean {
    return (
      this.props.link === LinkType.Download &&
      typeof this.props.linkDownload !== 'undefined'
    );
  }

  private get classNames(): string {
    return classnames('Link', this.props.variant || LinkVariant.Default, {
      'Link--internal': !this.isExternal && !this.isDownload,
      'Link--external': this.isExternal,
      'Link--download': this.isDownload,
      'Button Button--primary Button--full Button--icon-right Button--big Button--auto-height':
        this.props.variant === LinkVariant.Button,
    });
  }

  private get anchorProps(): React.AnchorHTMLAttributes<HTMLAnchorElement> {
    return {
      href: getHref(this.props),
      className: this.classNames,
      download: this.isDownload && this.props.linkDownload ? '' : undefined,
      ...this.getDownloadOrTitleProp('title'),
      ...this.getTargetProp(),
      ...this.getRelProp(),
    };
  }

  private get icon(): IconVariant {
    return this.isExternal
      ? IconVariant.External
      : this.isDownload
      ? IconVariant.Download
      : IconVariant.ArrowRight;
  }

  private getDownloadOrTitleProp(prop: 'title' | 'download'): any {
    const asset = this.props.linkDownload;
    return this.isDownload && asset
      ? { [prop]: asset['caption'] || asset.name || '' }
      : {};
  }

  private getTargetProp(): any {
    return this.isExternal || this.isDownload ? { target: '_blank' } : {};
  }

  private getRelProp(): any {
    const prop =
      this.props.noFollow === true
        ? 'nofollow'
        : '' + this.isExternal
        ? ' noopener'
        : '';
    return { rel: prop };
  }

  render(): JSX.Element | null {
    const { href, ...rest } = this.anchorProps;
    return (
      <p className="small-margin">
        <LinkWrapper href={href} {...rest}>
          <span className="Link__text">
            <span>{this.props.label}</span>
            <Icon variant={this.icon} />
          </span>
        </LinkWrapper>
      </p>
    );
  }
}

export default Link;
