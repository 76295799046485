import { CmsSubsidiary } from '@/components/CmsInterfaces';
import { Icon, IconVariant } from '@/components/Icon';
import { Richtext } from '@/components/Richtext';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';
import React, { useEffect, useState } from 'react';

export interface ContactProps {
  contactSubsidiary: string;
  usePressAddress?: boolean;
  showIntro?: boolean;
}

export const getTelAnchor = (
  numberString: string,
  variant?: string
): JSX.Element => {
  const noSpaceNumber = numberString.replace(' ', '');
  const variantName = variant === 'fax' ? 'Fax' : 'Telefon';
  return (
    <a
      href={`tel:${noSpaceNumber}`}
      aria-label={`${variantName}nummer: ${numberString} (öffnet Telefonanwendung)`}
    >
      {numberString}
    </a>
  );
};

export const Contact: React.FC<React.PropsWithChildren<ContactProps>> = ({
  contactSubsidiary,
  usePressAddress = false,
  showIntro = false,
}) => {
  const [subsidiaries, setSubsidiaries] = useState<CmsSubsidiary[]>();

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`/api/data/subsidiaries`);
      if (response.ok) {
        setSubsidiaries(await response.json());
      }
    }
    fetchData();
  }, []);

  let contactData: CmsSubsidiary | null = null;
  if (subsidiaries) {
    const subsidiary = subsidiaries.find((s) => s.state === contactSubsidiary);
    contactData = subsidiary || null;
  }

  if (contactData && usePressAddress === true) {
    contactData.name = contactData.pressName || '';
    contactData.telephone = contactData.pressTelephone || '';
    contactData.fax = contactData.pressFax || '';
    contactData.email = contactData.pressEmail || '';
    contactData.website = contactData.pressWebsite || '';
    contactData.address = contactData.pressAddress || '';
  }

  return (
    <section className="Contact box">
      {contactData ? (
        <>
          {showIntro && contactData.pressVisdp && usePressAddress === true && (
            <div className="Contact__intro">{contactData.pressVisdp}</div>
          )}
          <figure className="Contact__logo Image Image--original">
            <div className="Image__container">
              <img
                src={`/assets/logos-llgs/${contactData.state}.svg`}
                className="Image__img"
                alt=""
                loading="lazy"
              />
            </div>
          </figure>
          <div className="Contact__container">
            {contactData.address && (
              <div className="Contact__address">
                <div className="Richtext">
                  <strong
                    dangerouslySetInnerHTML={replaceAbbreviations(
                      contactData.name
                    )}
                  ></strong>
                  <Richtext html={true}>
                    {replaceAbbreviations(contactData.address).__html}
                  </Richtext>
                </div>
              </div>
            )}
            <div className="Contact__address">
              <div className="Richtext">
                {contactData.telephone && (
                  <>
                    <Icon variant={IconVariant.Hotline} />
                    Telefon {getTelAnchor(contactData.telephone)}
                    <br />
                  </>
                )}
                {contactData.fax && (
                  <>
                    <Icon variant={IconVariant.Fax} />
                    Telefax {getTelAnchor(contactData.fax, 'fax')}
                    <br />
                  </>
                )}
                {contactData.email && (
                  <>
                    <Icon variant={IconVariant.Email} />
                    <a
                      href={`mailto:${contactData.email}`}
                      title={`Email an: ${contactData.email}`}
                    >
                      {contactData.email}
                    </a>
                    <br />
                  </>
                )}
                {contactData.website && (
                  <>
                    <Icon variant={IconVariant.Website} />
                    <a
                      href={contactData.website}
                      target="_blank"
                      aria-label={`Externer Link: ${contactData.website} (öffnet neues Fenster)`}
                      rel="noopener noreferrer"
                    >
                      {contactData.website}
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </section>
  );
};
