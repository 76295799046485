import {
  ID_EUROJACKPOT,
  ID_GLUECKSPIRALE,
  ID_KENO,
  ID_LOTTO6AUS49,
  ID_SPIEL77,
  ID_RUBBELLOSE,
  T_EUROJACKPOT,
  T_GLUECKSSPIRALE,
  T_KENO,
  T_LOTTO6AUS49,
  T_PLUS5,
  T_SPIEL77,
  T_SUPER6,
  T_RUBBELLOSE,
} from '@/config/game/ids';

export type GameId =
  | T_EUROJACKPOT
  | T_GLUECKSSPIRALE
  | T_KENO
  | T_LOTTO6AUS49
  | T_RUBBELLOSE;
export type AdditionalGameId = T_PLUS5 | T_SUPER6 | T_SPIEL77;
export type Priority = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface TimeTableDay {
  day: string;
  start: string;
  end: string;
  game: GameId;
  priority: Priority;
}

export interface TimeTableAddition {
  readonly game: GameId;
  readonly additionalGame: AdditionalGameId;
  readonly threshold: number;
}

export interface TimeTableJackpot {
  readonly value: number;
  readonly game: GameId;
  readonly priority: Priority;
}

interface TeaserTimeTable {
  readonly getDays: () => readonly TimeTableDay[];
  readonly getJackpots: () => readonly TimeTableJackpot[];
  readonly games: readonly GameId[];
  readonly defaultHighlight: GameId;
  readonly additions: readonly TimeTableAddition[];
}

const getDays = (): readonly TimeTableDay[] => {
  return [
    {
      day: 'Montag',
      start: '00:00',
      end: '23:59',
      game: ID_EUROJACKPOT,
      priority: 0,
    },
    {
      day: 'Dienstag',
      start: '00:00',
      end: '18:44',
      game: ID_EUROJACKPOT,
      priority: 0,
    },
    {
      day: 'Dienstag',
      start: '18:45',
      end: '23:59',
      game: ID_LOTTO6AUS49,
      priority: 0,
    },
    {
      day: 'Mittwoch',
      start: '00:00',
      end: '17:59',
      game: ID_LOTTO6AUS49,
      priority: 0,
    },
    {
      day: 'Mittwoch',
      start: '18:00',
      end: '19:00',
      game: ID_LOTTO6AUS49,
      priority: 4,
    },
    {
      day: 'Mittwoch',
      start: '19:01',
      end: '23:59',
      game: ID_GLUECKSPIRALE,
      priority: 0,
    },
    {
      day: 'Donnerstag',
      start: '00:00',
      end: '23:59',
      game: ID_EUROJACKPOT,
      priority: 3,
    },
    {
      day: 'Freitag',
      start: '00:00',
      end: '18:44',
      game: ID_EUROJACKPOT,
      priority: 3,
    },
    {
      day: 'Freitag',
      start: '18:45',
      end: '23:59',
      game: ID_LOTTO6AUS49,
      priority: 0,
    },
    {
      day: 'Samstag',
      start: '00:00',
      end: '18:59',
      game: ID_LOTTO6AUS49,
      priority: 0,
    },
    {
      day: 'Samstag',
      start: '19:00',
      end: '20:00',
      game: ID_LOTTO6AUS49,
      priority: 4,
    },
    {
      day: 'Samstag',
      start: '20:01',
      end: '23:59',
      game: ID_KENO,
      priority: 0,
    },
    {
      day: 'Sonntag',
      start: '00:00',
      end: '23:59',
      game: ID_KENO,
      priority: 0,
    },
  ];
};

const getJackpots = (): readonly TimeTableJackpot[] => {
  return [
    {
      value: 15,
      game: ID_LOTTO6AUS49,
      priority: 2,
    },
    {
      value: 20,
      game: ID_LOTTO6AUS49,
      priority: 4,
    },
    {
      value: 50,
      game: ID_EUROJACKPOT,
      priority: 1,
    },
    {
      value: 75,
      game: ID_EUROJACKPOT,
      priority: 5,
    },
    {
      value: 30,
      game: ID_LOTTO6AUS49,
      priority: 6,
    },
    {
      value: 120,
      game: ID_EUROJACKPOT,
      priority: 7,
    },
  ];
};

export const teaserTimeTable: TeaserTimeTable = {
  getJackpots,
  getDays,
  games: [
    ID_LOTTO6AUS49,
    ID_EUROJACKPOT,
    ID_GLUECKSPIRALE,
    ID_KENO,
    ID_RUBBELLOSE,
  ],
  defaultHighlight: ID_LOTTO6AUS49,
  additions: [
    {
      game: ID_LOTTO6AUS49,
      additionalGame: ID_SPIEL77,
      threshold: 2,
    },
  ],
};
