import React from 'react';

export function extractText(nodes: React.ReactNode): string {
  if (Array.isArray(nodes)) {
    return nodes.map((node) => extractText(node)).join('');
  }

  if (typeof nodes === 'string') {
    return nodes;
  }

  if (React.isValidElement(nodes)) {
    if (nodes.props.children) {
      return extractText(nodes.props.children);
    }
    else if (nodes.props.dangerouslySetInnerHTML) {
      return nodes.props.dangerouslySetInnerHTML['__html'];
    }
  } 

  return '';
}
